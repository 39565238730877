interface ExacTagPayload {
    pt: string
}

type RegisterFunction = (triggerId: string, triggerFunction: () => ExacTagPayload) => void

interface ExactagInterface {
    register: RegisterFunction
}

interface Lhotse {
    exactag: ExactagInterface
}

declare global {
    interface Window {
        lhotse: Lhotse
    }
}

export class Exactag {
    registerExactag() {
        try {
            const exactag = window.lhotse.exactag
            exactag.register("loginPage", () => {
                return {
                    pt: "Login",
                }
            })
        } catch (ignore) {
            /*         */
        }
    }
}

import { hasPostalCode, PostalCodeInput } from "../../fragments/postalCodeInputField/postalCodeInputField"
import JavascriptChallenge from "../../javascript_challenge"
import { Exactag } from "../exactag"
import { hasMobileUserAgent } from "../utils"
import "./loginPage.scss"
import { tracking } from "@otto-ec/tracking-bct"
import { querySelectorNonNull } from "../../document"
import { appendSpinnerToFormButtons } from "../../spinner"
import { onAllPreloadScriptsLoaded } from "../../utils"
import { AuthField } from "../../fragments/authField/authField"
import { PasswordFieldTracking } from "../../fragments/passwordInputField/passwordFieldTracking"
import { validateCurrentEmail } from "../../fragments/emailInputField/currentEmailValidator"
import { validateFieldWith } from "../../fragments/authField/validateFieldWith"
import { validateCurrentPassword } from "../../fragments/passwordInputField/currentPasswordValidator"

class LoginPage {
    private readonly emailField: AuthField | undefined
    private readonly passwordField: AuthField
    private readonly postalCodeComponent: PostalCodeInput | undefined
    private readonly loginFlow: string

    constructor(
        document: Document,
        window: Window,
        private readonly exactag = new Exactag(),
    ) {
        if (document.querySelector(".fti_js_login_welcomeBack")) {
            this.loginFlow = "SoftLoggedOut"
        } else {
            this.loginFlow = "Login"
            const emailField: HTMLOcAuthFieldV1Element = querySelectorNonNull(document, ".fti_js_email_field")
            this.emailField = new AuthField(emailField)
            validateFieldWith(this.emailField, validateCurrentEmail)
        }
        const rememberMeCheckbox: HTMLInputElement | null = document.querySelector(".fti_js_rememberMeCheckbox")
        if (rememberMeCheckbox) {
            this.setRememberMeForMobileDevices(window, rememberMeCheckbox).catch((e: unknown) => {
                console.error(e)
            })
        }

        const passwordField: HTMLOcAuthFieldV1Element = querySelectorNonNull(document, ".fti_js_password_field")
        this.passwordField = new AuthField(passwordField)
        validateFieldWith(this.passwordField, validateCurrentPassword)
        const passwordFieldTracking = new PasswordFieldTracking({
            showPasswordTrackingEvent: {
                user_LoginActivity: ["click_ShowPassword"],
                user_LoginFlow: [this.loginFlow],
            },
            hidePasswordTrackingEvent: {
                user_LoginActivity: ["click_HidePassword"],
                user_LoginFlow: [this.loginFlow],
            },
        })
        passwordFieldTracking.trackUserInteractions(passwordField)

        this.addChallenge(document)
        if (hasPostalCode(document)) {
            this.postalCodeComponent = new PostalCodeInput(document)
            this.postalCodeComponent.registerEventListeners()
        }
        this.setBlockedTimer(document)
        this.exactag.registerExactag()

        appendSpinnerToFormButtons(document, ".fti_js_togglePasswordContainer")
    }

    private addChallenge(document: Document) {
        const challengeValue = document.querySelector(".fti_challenge-value")?.getAttribute("data-value")
        if (challengeValue) {
            const formElement: HTMLFormElement = querySelectorNonNull(document, ".fti_login_loginFormContainer")
            const javascriptChallenge = new JavascriptChallenge(formElement)
            javascriptChallenge.setHiddenChallengeValue(challengeValue)
        }
    }

    private setBlockedTimer(document: Document) {
        const blockedTimer = document.querySelector("#fti_js_blocked_counter")
        if (blockedTimer != null) {
            const display = blockedTimer as HTMLElement
            const unblockTimeSeconds = Math.floor(new Date().getTime() / 1000 + 3600)
            const interval = setInterval(() => {
                const remainingBlockedSeconds = this.calculateSecondsFromNowUntil(unblockTimeSeconds)
                if (remainingBlockedSeconds <= 0) {
                    display.innerHTML = "00:00"
                    clearInterval(interval)
                } else {
                    display.innerHTML = this.formatCountdown(remainingBlockedSeconds)
                }
            }, 1000)
        }
    }

    private calculateSecondsFromNowUntil(untilSeconds: number) {
        return Math.floor(untilSeconds - new Date().getTime() / 1000)
    }

    private formatCountdown(timer: number) {
        return this.formatCountdownPart(Math.floor(timer / 60)) + ":" + this.formatCountdownPart(Math.floor(timer % 60))
    }

    private formatCountdownPart(timePart: number) {
        return timePart < 10 ? `0${timePart.toString()}` : timePart.toString()
    }

    private async setRememberMeForMobileDevices(window: Window, rememberMeCheckbox: HTMLInputElement) {
        const isCustomerSelection = rememberMeCheckbox.hasAttribute("data-js-customerSelection")
        if (isCustomerSelection) {
            return
        }
        const isMobileUserAgent = hasMobileUserAgent(window.navigator.userAgent)
        const isLandscape = window.matchMedia("(orientation: landscape)").matches || false
        const isPortrait = window.matchMedia("(orientation: portrait)").matches || false
        const isMobileSize = (isPortrait && window.innerWidth < 768) || (isLandscape && window.innerWidth < 1024)
        const isTouchDevice = window.navigator.maxTouchPoints > 0 || window.matchMedia("(pointer: coarse)").matches || false
        const isMobileDevice = isMobileUserAgent || (isMobileSize && isTouchDevice)
        if (isMobileDevice) {
            rememberMeCheckbox.checked = isMobileDevice
        }
        await tracking.submitMerge({
            user_LoginRememberMePreselected: [isMobileDevice.toString()],
        })
    }
}

onAllPreloadScriptsLoaded(() => new LoginPage(document, window))

export default LoginPage

import { fetchPostalCodeInputValidation } from "../api"
import { querySelectorNonNull } from "../../document"

const postalCodeSelector = "#fti_input_postalCode"

class PostalCodeInput {
    private readonly $postalCodeFieldElement: HTMLOcAuthFieldV1Element
    private readonly $postalCodeInputElement: HTMLInputElement
    private readonly $postalCodeInputHintElement: HTMLDivElement
    private onErrorStatusChangeCallback?: () => void
    private onErrorStatusChangeCallbackCallee?: () => void
    private hasError = false

    constructor(document: Document) {
        this.$postalCodeFieldElement = querySelectorNonNull(document, ".fti_js_postalCode_field")
        this.$postalCodeInputElement = querySelectorNonNull(document, postalCodeSelector)
        this.$postalCodeInputHintElement = querySelectorNonNull(document, ".fti_js_postalCode_input_hint")
    }

    registerEventListeners() {
        this.$postalCodeInputElement.addEventListener("focusout", this.onFocusOut)
    }

    private onFocusOut = () => {
        this.$postalCodeInputElement.removeEventListener("input", this.onInput)
        this.$postalCodeInputElement.addEventListener("input", this.onInput)
        this.validateInput().catch((e: unknown) => {
            console.error(e)
        })
    }

    private onInput = () => {
        this.validateInput().catch((e: unknown) => {
            console.error(e)
        })
    }

    private async validateInput() {
        try {
            const responseData = await fetchPostalCodeInputValidation(this.$postalCodeInputElement.value ?? "")
            const errorMessage = responseData.errorMessage ?? ""
            this.hasError = errorMessage != ""
            this.hasError ? this.showError(errorMessage) : this.hideError()
            this.onErrorStatusChange()
        } catch (exception) {
            console.error(exception)
        }
    }

    showError(errorMessage: string) {
        const errorVariant = "error"
        this.$postalCodeInputHintElement.slot = errorVariant
        this.$postalCodeInputHintElement.textContent = errorMessage
        this.$postalCodeFieldElement.variant = errorVariant
        this.$postalCodeInputElement.setAttribute("aria-invalid", "true")
    }

    hideError() {
        this.$postalCodeInputHintElement.slot = "default"
        this.$postalCodeFieldElement.variant = "default"
        this.$postalCodeInputElement.setAttribute("aria-invalid", "false")
    }

    private onErrorStatusChange() {
        this.onErrorStatusChangeCallback?.call(this, !this.hasError, this.onErrorStatusChangeCallbackCallee)
    }
}

function hasPostalCode(document: Document) {
    return document.querySelector(postalCodeSelector) != null
}

export { PostalCodeInput, hasPostalCode }
